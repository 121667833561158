import { AIPrompt } from './ai.types'

export const FORMAT_HTML =
  'Please generate content in HTML format suitable for a rich text editor. Wrap text within <p> tags to denote paragraphs. Do not include <code> blocks, codeblock backticks like ```html, or any other code-related formatting unless explicitly requested. Focus on creating clean, reader-friendly HTML content that can be directly utilized in a rich text editing environment without the need for further styling or code block removal.'
export const FORMAT_PLAINTEXT =
  'Generate responses in plain text format. Do not include quotes around the text unless explicitly stated in the instructions. The output should be clean, with a focus on delivering the content straightforwardly and without any additional formatting characters, such as quotes, unless such formatting is a specific requirement of the request.'

export const getAIPrompt = ({
  content = '',
  type,
  options,
  format,
  temperature = 0.7,
}: AIPrompt): {
  user: string
  system: string
  temperature?: number
} => {
  let user = content
  let system = `You are a helpful assistant designed to output ${format}.`

  switch (type) {
    case 'improve':
      system = `You are a helpful writing assistant. You will be provided with content, and your task is to convert them to improve the writing. If there are no improvements then leave content as is. Format in ${format}.`
      break

    case 'fixSpelling':
      system = `You are a helpful writing assistant. Correct any spelling and grammatical errors in the following text. If there are no errors, leave the text as is. Format in ${format}.`
      break

    case 'shorten':
      system = `Make this text as short as possible while keeping only the essential meaning. Cut ruthlessly. Format in ${format}.`
      break

    case 'continueWriting':
      system =
        system = `You are a helpful writing assistant. You will be provided with content, and your task is to keep the original text intact and extend it with a brief, natural continuation. Aim to maintain the style, tone, and context of the original while adding just a few more sentences that flow naturally from where it ended. The original text should remain unchanged. Format in ${format}.`
      break

    case 'changeVoice':
      system = `You are a helpful writing assistant. Change the tone of the following text to be more ${options?.tone}. Format in ${format}.`
      break

    case 'translate':
      system = `You are a translation assistant. Translate the text into ${options?.translationLanguage}. Format in ${format}.`
      break

    case 'rewrite':
      system = `You are a helpful writing assistant. Rewrite the provided content using different wording while preserving the exact same meaning and key points. Maintain the same length, tone, and level of formality as the original. Do not add new information or remove existing details. Format in ${format}.`
      break

    case 'promptTitle':
      system = `You are a helpful writing assistant producing content for a micro website landing page for small businesses. Unless specified otherwise, the content should be no longer than 100 words. Format in ${format}.`
      if (content) {
        user = `User provided prompt: ${options?.title}

        ${content}`
      } else {
        user = `User provided prompt: ${options?.title}`
      }

      break

    case 'custom':
      system = `${format}.`
      if (content) {
        user = `${options?.title}

          ${content}`
      } else {
        user = `User provided prompt: ${options?.title}`
      }

      break

    default:
      throw new Error('Prompt type is missing')
  }

  return {
    user,
    system,
    temperature,
  }
}
