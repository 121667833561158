import { CrossLargeIcon, MagicIcon } from '@fingertip/icons'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  className?: string
  disabled?: boolean
}
export const AskAiButton = ({ setOpen, open, className, disabled }: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => setOpen(!open)}
      type="button"
      size="xs"
      className={className}
      variant="magicSecondary"
      disabled={disabled}
    >
      {open ? (
        <CrossLargeIcon className="size-4 mr-1" />
      ) : (
        <MagicIcon className="size-4 mr-1" />
      )}
      {t('write_with_ai')}
    </Button>
  )
}
